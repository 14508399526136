import React from 'react';

const ProjectList = ({ title, content, link, image }) => {

  if (!title){
    return(
      <div className="text-center">Sorry, no projects currently...</div>
    );
  }
  else{

    return (
      <div className="row" >
        <div className="ten columns offset-by-one">
          <div className="labs-project">
            <div className="labs-project-content">
              <h2 className="list-card-heading">{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <div className="marg-top-5">
                <a className="button button-underline" href={link} target="_blank" rel="noopener noreferrer">Check out {title}</a>
              </div>
            </div>
            <div className="labs-project-image" style={{ backgroundImage: `url(${image})` }} aria-labelledby={title}/>
          </div>
        </div>
      </div>
    );
  }
};

export default ProjectList;
  
