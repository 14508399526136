import React from 'react';
import PropTypes from 'prop-types';

import WonderLink from 'e84-website-styles/src/components/WonderLink';
import Callout from 'e84-website-styles/src/components/Callout';

const Collaborate = ({ helperClass }) => {
  return (
    <Callout helperClass={`site-callout__collaborate text-center ${helperClass || ''}`}>

      <h1>Let&#39;s Collaborate</h1>

      <p>
        Remote sensing? Big data? A project so cool, you just had to tell us? Get in touch, we&#39;d love to hear about it.
      </p>

      <WonderLink to="https://www.element84.com/contact" className="button button-prim_gradient button-lg button-shadow">
        Drop us a line
      </WonderLink>

    </Callout>
  );
};

Collaborate.propTypes = {
  helperClass: PropTypes.string,
};

export default Collaborate;
