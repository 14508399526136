import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { nav_routes } from 'helpers/routes';

import Footer from 'e84-website-styles/src/components/Footer';

import LabsLayout from 'components/Labs/LabsLayout';
import LabsHero from 'components/Labs/LabsHero';
import ProjectList from 'components/Labs/ProjectList';
import Collaborate from 'components/Collaborate';

const IndexPage = ({ data }) => {

  const projects = JSON.parse(data.jsonPage.customFields);
  //const yoast = JSON.parse(data.jsonPage.yoast);
  //const metadesc = JSON.stringify(yoast.metadesc);
  const title = JSON.stringify(data.jsonPage.html).replace(/['"]+/g, '').replace('<p>', '').replace('</p>', '');

  const helmet_settings = {
    title: 'E84 Labs - Experiments in Action',
  };

  return (
    <LabsLayout>

      <Helmet {...helmet_settings}>
        <body className="labs-page"/>
      </Helmet>

      <LabsHero modifierClass="homepage-hero">
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
      </LabsHero>

      <section className="site-section" id="LabsProjects">
        <div className="container">
          <h1 className="narrow text-center marg-bot-5">Experiments in Action</h1>
          {Object.keys(projects.list_field).map(function (key) {
            if (!projects.list_field){
              return(<div className="text-center">Sorry, no projects currently...</div>);
            }
            else{
              return(
                <ProjectList key={key}
                  title={projects.list_field[key].title}
                  content={projects.list_field[key].content}
                  image={projects.list_field[key].image}
                  link={projects.list_field[key].link} />
              );
            }
          })}
        </div>
      </section>

      <Collaborate />

      <Footer routes={nav_routes} />

    </LabsLayout>
  );

};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  {
    jsonPage(slug: { eq: "labs_page" }) {
      html
      customFields
    }
  }
`;