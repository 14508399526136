import React from 'react';
import PropTypes from 'prop-types';
import LabsHeroImage from './LabsHeroImage';

const LabsHero = ({ children }) => {
  return (
    <section className='site-section site-hero labs-hero'>
      <div className="container text-center">
        {children}
        <LabsHeroImage/>
      </div>
    </section>
  );
};

LabsHero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  modifierClass: PropTypes.string,
};

export default LabsHero;
