import React from 'react';
import PropTypes from 'prop-types';

const LabsHeroImage = () => {
  return (
    <div className="labs-hero-image">
      <div className="row">
        <div className="five columns offset-by-two labs-hero-list">
          <h2>1</h2>
          <p>Why will this <strong>improve the world?</strong></p>
        </div>
        <div className="five columns labs-hero-list">
          <h2>2</h2>
          <p>Why will this <strong>improve someone’s life?</strong></p>
        </div>
      </div>
    </div>
  );
};

LabsHeroImage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  modifierClass: PropTypes.string,
};

export default LabsHeroImage;



